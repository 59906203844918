
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.sidebar-wrap {
  background-color: $white;
  width: 255px;
  :deep(.s-menu) {
    background: #fff;
    &.s-menu-item {
      transition: opacity 0.3s;
    }
    .s-menu-item-group {
      .s-menu-item {
        padding-left: 40px !important;
        a {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .s-sub-menu-wrap {
        .s-menu-item {
          padding-left: 60px !important;
        }
      }
    }
  }
  .sidebar-menu {
    position: fixed;
    z-index: $zindex-sticky;
  }
}
