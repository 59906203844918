
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.application-main {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.skippy {
  display: block;
  padding: 1em;
  color: #fff;
  text-align: center;
  background-color: $theme-blue;
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  &:focus {
    width: 100%;
    height: 56px;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: solid 1px $white;
    position: fixed;
    top: 0;
    z-index: $zindex-sticky + 1;
    outline: none !important;
  }
  & span {
    border: dotted 1px #fff;
  }
}

#portal-app-content {
  overflow: auto;
}

.branding-wrap {
  min-height: 56px;
  .branding-logo-wrap {
    width: auto;
    height: 24px;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .side-bar {
    width: 0;
    z-index: 9;
    // :deep( .sidebar-menu ){
    //   transform: translateX(-100%);
    //   transition: all 0.3s ease-in-out;
    // }
    // &.show :deep( ){
    //   .sidebar-menu {
    //     transform: translateX(0%);
    //     background-color: $white;
    //   }
    // }
  }
}
