.navbar-nav {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 2px;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  &:first-child {
    margin-bottom: 4px;
  }

  &.navbar-right {
    justify-content: center;
    .toggle-side-bar-icon {
      display: none;
    }
  }

  .nav-item-parent {
    cursor: pointer;
  }

  .nav-link {
    padding-right: 12px;
    padding-left: 12px;
    color: $white;
    line-height: 40px;
    display: inline-block;
    height: 100%;
    white-space: nowrap;
    opacity: 0.9;
    text-decoration: none;
    &.active,
    &:hover,
    &:focus {
      text-decoration: none;
      opacity: 1;
    }

    &:focus {
      outline: auto;
    }

    &.active {
      font-weight: bold;
    }
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}
.nav-menu {
  width: 100%;
  background: rgb(255, 255, 255);
  position: fixed;
  top: 90px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 5%);
  min-height: 200px;
  max-height: 400px;
  z-index: $zindex-sticky + 2;
  transition: height 0.3s ease-in-out;
  margin: -16px;
  overflow: auto;
}
.nav-menu-mask {
  position: fixed;
  top: 90px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #333;
  opacity: 0.3;
  z-index: $zindex-sticky + 1;
  text-align: center;
  padding-top: 250px;
}
.menu-enter-active {
  animation: menu-in 0.5s;
}
.menu-leave-active {
  animation: menu-in 0.5s reverse;
}
@keyframes menu-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.s-select-list ul li .nav-link {
  text-decoration: none;
}
.check-menu {
  color: $theme-blue;
}
.menu-link {
  text-decoration: none;
  color: $dark;
}

.navbar-expand {
  @include media-breakpoint-up(md) {
    flex-flow: row nowrap;
    justify-content: flex-start;

    &.navbar-nav {
      flex-direction: row;
      justify-content: left;
      &:first-child {
        margin-bottom: 0px;
      }

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 12px;
        padding-left: 12px;
        line-height: 40px;
        border-radius: 3px;
        margin-right: 4px;
        text-decoration: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }

        &.active,
        &:focus,
        &.router-link-active {
          background-color: rgba($color: #000000, $alpha: 0.2);
        }
      }

      &.navbar-right {
        .nav-link {
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .toggle-side-bar-icon {
      display: inline-block !important;
    }
  }
}

.user-info-email {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  height: auto !important;
  border-bottom: 1px solid #ddd;
  cursor: default !important;
  color: $font-400 !important;
  h4 {
    margin: 0;
    line-height: 40px !important;
  }
  .email-text {
    font-size: 14px;
    line-height: 1em !important;
    width: 240px;
    white-space: normal;
    word-break: break-all;
    text-align: center;
  }
  &:hover {
    background-color: inherit !important;
  }
}
.info-list {
  border-bottom: 1px solid #ddd;
  .info-item {
    width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.has-icon {
      padding: 0 30px 0 0;
      position: relative;
      .default-icon {
        position: absolute;
        right: 0;
        top: 0;
        color: $blue;
        float: right;
      }
    }
  }
}
.log-out {
  &:hover {
    color: $theme-red !important;
  }
}

@media screen and (max-width: 576px) {
  .user-info-email {
    .email-text {
      width: auto;
      white-space: normal;
      word-break: break-all;
    }
  }
}
