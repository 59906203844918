
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.branding-wrap {
  min-height: 56px;
  .branding-logo-wrap {
    width: auto;
    height: 24px;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
    }
  }
}
